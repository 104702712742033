<template>
  <div class="box-valor">
    <span
      v-for="(nivel, index) in niveisPerfil"
      :class="'legenda ' + nivel.toLowerCase()"
      :id="'legenda-' + nivel + '-' + tipoExibido + '-' + lado"
      :key="'legenda-' + nivel + '-' + lado"
      @mouseover="handleHover(index)"
      @mouseleave="handleHoverLeave()"
    >
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'legenda-' + nivel + '-' + tipoExibido + '-' + lado"
        triggers="hover"
        placement="top"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        {{ nivel }}
      </b-tooltip>
    </span>
    <img :src="urlExibida" class="img-perfil abs" v-if="urlExibida != ''" />
    <img :src="urlPerfilDigital" class="img-perfil abs" />
    <span
      v-for="nivel in niveisPerfil"
      :class="'perfil-valor ' + nivel.toLowerCase()"
      :key="nivel"
    >
      {{ getPorcentagem(nivel) }}
    </span>
    <span class="valor-perfil" :class="{ nulo: valorPerfil == '-' }">
      {{ valorPerfil }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PerfilDigital",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
    lado: String,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      perfilDigital0: "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza.png",
      perfilDigital1: "/img/superbox/bloco-sonar/PerfilDigital-Restrito1.png",
      perfilDigital2: "/img/superbox/bloco-sonar/PerfilDigital-Reduzido2.png",
      perfilDigital3: "/img/superbox/bloco-sonar/PerfilDigital-Neutro3.png",
      perfilDigital4: "/img/superbox/bloco-sonar/PerfilDigital-Elevado4.png",
      perfilDigital5: "/img/superbox/bloco-sonar/PerfilDigital-Amplo5.png",
      perfilDigitalHover0:
        "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza-1.png",
      perfilDigitalHover1:
        "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza-2.png",
      perfilDigitalHover2:
        "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza-3.png",
      perfilDigitalHover3:
        "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza-4.png",
      perfilDigitalHover4:
        "/img/superbox/bloco-sonar/PerfilDigital-Nulo0-cinza-5.png",

      tipoExibidoDelay: this.tipoExibido,

      niveisPerfil: ["Restrito", "Reduzido", "Neutro", "Elevado", "Amplo"],

      urlExibida: "",
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      setTimeout(() => {
        this.tipoExibidoDelay = newVal;
      }, 500);
    },
  },

  methods: {
    handleHover(index) {
      this.urlExibida = this["perfilDigitalHover" + index];
    },

    handleHoverLeave() {
      this.urlExibida = "";
    },

    getPorcentagem(nivel) {
      if (this.tipoExibidoDelay != "documento") {
        const atributo = `bk_111_001_num_${
          this.tipoExibidoDelay
        }_perfil_digital_proporcao_${nivel.toLowerCase()}`;
        return this.resultados[atributo] != "-"
          ? this.resultados[atributo] + "%"
          : this.resultados[atributo];
      } else {
        return null;
      }
    },
  },

  created() {},

  computed: {
    valorPerfil() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[`boxauxsuppf002_class_documento_perfil_digital`];
      } else {
        return this.resultados[
          `bk_111_001_class_${this.tipoExibidoDelay}_perfil_digital`
        ];
      }
    },

    urlPerfilDigital() {
      if (this.tipoExibidoDelay == "documento") {
        var atributo = `boxauxsuppf002_class_documento_perfil_digital`;
      } else {
        var atributo = `bk_111_001_class_${this.tipoExibidoDelay}_perfil_digital`;
      }
      let valor = this.resultados[atributo];

      const niveisPerfil = {
        Restrito: this.perfilDigital1,
        Reduzido: this.perfilDigital2,
        Neutro: this.perfilDigital3,
        Elevado: this.perfilDigital4,
        Amplo: this.perfilDigital5,
      };

      return niveisPerfil[valor] || this.perfilDigital0;
    },
  },

  mounted() {
    // this.urlExibida = this.urlPerfilDigital;
  },
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  z-index: -1;

  &.horiz {
    padding: 2px 10px 5px 10px;
    background-color: white;
    border-radius: 0 10px 10px 0;
    flex-direction: column;
    height: 100%;
  }

  &.distancia {
    padding: 2px 10px 10px 10px;

    justify-content: space-between;
  }
}

.valor-perfil {
  border: 2px solid #a69bff;
  border-radius: 15px;
  width: 80px;
  position: relative;
  bottom: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.nulo {
    border: 2px solid #b8b8b8;
  }
}

.img-perfil {
  width: 65%;
  margin: 0px 0 0 0;
}

.perfil-valor {
  position: absolute;
  font-size: 11px;

  &.amplo {
    right: 45px;
    bottom: 38px;
  }
  &.elevado {
    right: 72px;
    bottom: 48px;
  }
  &.neutro {
    bottom: 51px;
    right: 104px;
  }
  &.reduzido {
    left: 80px;
    bottom: 42px;
  }
  &.restrito {
    left: 57px;
    bottom: 26px;
  }
}

.legenda {
  position: absolute;
  width: 25px;
  background-color: transparent;
  z-index: 2;

  &.amplo {
    left: 167px;
    bottom: 55px;
    height: 28px;
  }
  &.elevado {
    left: 134px;
    bottom: 67px;
    height: 23px;
  }
  &.neutro {
    left: 104px;
    bottom: 65px;
    height: 25px;
  }
  &.reduzido {
    left: 74px;
    bottom: 56px;
    height: 29px;
  }
  &.restrito {
    left: 42px;
    bottom: 31px;
    height: 29px;
  }
}

.abs {
  position: absolute;
  top: 40px;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
