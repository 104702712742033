<template>
  <div class="bar">
    <canvas
      class="canvas"
      :id="'superBoxRadar'"
      height="365px"
      width="400px"
    ></canvas>
  </div>
</template>

<script>
import { useSkynetStore } from "@/stores/skynet";
import { mapActions } from "pinia";

export default {
  name: "RadarChart",
  props: {
    data: Array,
    score: Object,
  },

  data() {
    return {
      dataSetsCustom: [],
      MyChart: null,
      keysInOrder: [
        "risco_credito_score",
        "risco_localidade_score",
        "potencial_compra_score",
        "fragilidade_social_score",
        "longevidade_score",
      ],
    };
  },

  methods: {
    setDataset() {
      this.dataSetsCustom = [];

      var scores = this.keysInOrder.map((key) => this.score[key]);
      scores = scores.map((num) => (num === null ? 0 : num));

      this.dataSetsCustom.push({
        label: "",
        data: scores,
        backgroundColor: "rgb(67, 99, 216, 0.4)",
        hoverBackgroundColor: "rgb(67, 99, 216, 0.8)",
      });
    },

    createChart() {
      const ctx = document.getElementById(`superBoxRadar`);

      this.MyChart = new Chart(ctx, {
        type: "radar",
        data: {
          labels: [
            "Risco de Crédito",
            "Risco de Localidade",
            "Potencial de Compra",
            "Fragilidade Social",
            "Score de Longevidade",
          ],
          datasets: this.dataSetsCustom,
        },
        options: {
          maintainAspectRatio: true,
          responsive: false,
          scaleBeginAtZero: true,

          legend: {
            display: false,
          },

          scale: {
            gridLines: {
              lineWidth: 1,
              color: "#00BA00",
            },
            pointLabels: {
              fontStyle: "bold",
              fontSize: 12,
              callback: function (label) {
                const words = label.split(" ");
                return words;
              },
            },
            ticks: {
              suggestedMin: 250,
              suggestedMax: 500,
            },
          },
          title: {
            display: false,
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
            },
          },
        },
      });
    },
  },

  created() {},

  mounted() {
    if (this.MyChart != null) {
      this.MyChart.destroy();
    }
    this.setDataset();

    this.createChart();
  },
};
</script>

<style lang="scss" scoped>
.bar {
  min-width: 100%;
  cursor: pointer;
}
.canvas {
  margin: 0px;
}
</style>
