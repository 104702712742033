<template>
  <div class="contentFull" id="contentsb">
    <div class="content-sb" v-if="!loading">
      <div class="header">
        <span class="icons-header"> </span>
        <h1 class="header-title">Superbox - Pessoa Física</h1>
      </div>
      <div class="body">
        <div class="cracha">
          <div class="col">
            <div class="info-cracha">
              <span class="info-title">CPF:</span>
              <span class="info-res">{{ formatarCPF(valorCPF) }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title"> Nome completo: </span>
              <span class="info-res">{{ nomeCompletoFiltro }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title" :id="'ResultadosSuperBox-0'">
                Data e hora da consulta:
              </span>
              <span class="info-res">{{ data }} | {{ hora }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title" :id="'ResultadosSuperBox-1'">
                Código da consulta:
              </span>
              <span class="info-res">{{ codigo_agrupador }}</span>
            </div>
            <div
              class="info-cracha data_ref"
              v-if="valorDataRef != '' && formataData(valorDataRef) != data"
            >
              <span class="info-title data_ref"> Data de referência: </span>
              <span class="info-res">{{ formataData(valorDataRef) }}</span>
            </div>
            <img class="img-logo-x" :src="logoX" />
          </div>
          <div class="col">
            <img class="img-logo" :src="logoSB" />
          </div>
        </div>
        <div class="info-blocos">
          <BlocoStatus
            v-for="(bloco, index) in listaBlocos"
            @scroll-to-block="scrollToBlock"
            :key="index"
            :bloco="bloco"
            :index="index"
            :status="blocoStatus"
          />
        </div>
      </div>

      <BlocoPFDadosCadastrais
        ref="blocoDadosCadastrais"
        :listaBlocos="listaBlocos"
        :valorCPF="valorCPF"
        :nomeCompleto="nomeCompletoFiltro"
        :resultados="resultadoDadosCadastrais"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :gerandoPdf="gerandoPdf"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFSonar
        ref="blocoSonarOutbox"
        :listaBlocos="listaBlocos"
        :resultados="resultadoSonar"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :consultaErros="consultaErros"
        :gerandoPdf="gerandoPdf"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFRenda
        ref="blocoRendaPresumida"
        :resultados="resultadoRendaPresumida"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFAssociacoesPessoais
        ref="blocoAssociaçõesPessoais"
        :resultados="resultadoAssociacoesPessoais"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFGeolocalidades
        ref="blocoEndereçoseGeolocalidades"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadoEnderecosGeolocalidades"
        :parametros="resultadoEnderecosGeolocalidadesParametros"
        :codigo_agrupador="codigo_agrupador"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :gerandoPdf="gerandoPdf"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFMapaRiscos
        ref="blocoMapaGeraldeRiscos"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadoMapaRiscos"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFPassagens
        :resultados="resultadoHistoricoDePassagens"
        ref="blocoHistóricodePassagens"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFConexoesEmpresas
        ref="blocoConexõescomEmpresas"
        :resultados="resultadoConexoesEmpresas"
        :resultadosFaturamento="resultadosFaturamentoPresumido"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :gerandoPdf="gerandoPdf"
        :codigo_agrupador="codigo_agrupador"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :valorDataRef="valorDataRef"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFAlertas
        ref="blocoAlertasGerais"
        :listaBlocos="listaBlocos"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
        @scrollToBlockAlerta="scrollToBlockAlerta"
      />
      <PrefTooltipTela @recebePrefTooltips="recebePrefTooltips" />

      <MenuLateralSuperbox
        @scroll-to-block="scrollToBlock"
        :listaBlocos="listaBlocos"
        :status="blocoStatus"
        :refAtual="refAtual"
      />

      <div v-for="(tt, i) in tooltipsBloco0" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <Loading />
    </div>
    <div class="footer-sup" v-if="!loading">
      <div class="footer-text">
        Clique<span class="link" @click="abrirContato">aqui</span>
        para reportar um problema com os dados desta página
        <span class="material-symbols-outlined question"> question_mark </span>
      </div>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";

import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mascaras } from "@/helpers/mascaras";
import { mapWritableState, mapActions } from "pinia";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

import produtosSuperBox from "@/helpers/produtos_superbox.json";
import BlocoStatus from "@/components/SuperBox/BlocosStatus.vue";
import BlocoPFDadosCadastrais from "@/components/SuperBox/BlocoPFDadosCadastrais.vue";
import BlocoPFAssociacoesPessoais from "@/components/SuperBox/BlocoPFAssociacoesPessoais.vue";
import BlocoPFGeolocalidades from "@/components/SuperBox/BlocoPFGeolocalidades.vue";
import BlocoPFSonar from "@/components/SuperBox/BlocoPFSonar.vue";
import BlocoPFMapaRiscos from "@/components/SuperBox/BlocoPFMapaRiscos.vue";
import BlocoPFPassagens from "@/components/SuperBox/BlocoPFPassagens.vue";
import BlocoPFConexoesEmpresas from "@/components/SuperBox/BlocoPFConexoesEmpresas.vue";
import BlocoPFRenda from "@/components/SuperBox/BlocoPFRenda.vue";
import BlocoPFAlertas from "@/components/SuperBox/BlocoPFAlertas.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import PrefTooltipTela from "@/pages/MinhaConta/PrefTooltipTela.vue";
import MenuLateralSuperbox from "@/components/SuperBox/MenuLateralSuperbox.vue";

export default {
  components: {
    Loading,
    BlocoStatus,
    BlocoPFDadosCadastrais,
    BlocoPFAssociacoesPessoais,
    BlocoPFGeolocalidades,
    BlocoPFSonar,
    BlocoPFMapaRiscos,
    BlocoPFPassagens,
    BlocoPFConexoesEmpresas,
    BlocoPFRenda,
    BlocoPFAlertas,
    PrefTooltipTela,
    MenuLateralSuperbox,
  },

  data() {
    return {
      logo: "/img/outbox_logo_azul.png",
      fundo: "/img/superbox/fundo.png",
      logoSB: "/img/superbox/logo-superbox.png",
      logoX: "/img/superbox/logoX.png",
      blocoStatus: new Array(9).fill("loading"),
      resultado: {},
      nomeCompleto: "",
      resultadoDadosCadastrais: {},
      resultadoSonar: {},
      resultadoRendaPresumida: {},
      resultadoAssociacoesPessoais: {},
      resultadoEnderecosGeolocalidades: {},
      resultadoEnderecosGeolocalidadesParametros: {},
      resultadoMapaRiscos: {},
      resultadoHistoricoDePassagens: {},
      resultadoConexoesEmpresas: {},
      resultadosFaturamentoPresumido: [],
      data: "",
      hora: "",
      loading: false,
      consultaErros: {},

      refAtual: "",
      refsList: [],
      observer: null,
      valorDataRef: "",
    };
  },

  props: {
    produtos: Array,
    campos: Array,
    contexto: Array,
    codigo_agrupador: String,
    gerandoPdf: Boolean,
    liberaTelaSuperbox: Boolean,
    liberaConsultaSuperbox: Boolean,
    produtosSelecionados: Array,
  },

  watch: {
    codigo_agrupador: {
      handler(newVal) {
        this.loading = true;
        setTimeout(() => {
          if (this.trilhaSelecionada.consulta == "Superbox") {
            Object.keys(
              this.contexto[this.contexto.length - 1].resultados
            ).forEach((chave) => {
              this.atribuiResultados(chave);
            });
            this.$emit("liberarPdf");
            this.loading = false;
          }
        }, 500);
      },
    },
  },

  methods: {
    formataData(data) {
      return data.slice(0, 10).split("-").reverse().join("/");
    },

    async geraConsultas() {
      if (this.liberaConsultaSuperbox) {
        this.contexto[this.contexto.length - 1].cod_agr = this.codigo_agrupador;
        this.produtos.forEach((produto) => {
          if (produto.Selecionado == true) {
            this.contexto[this.contexto.length - 1].resultados[produto.Box] = {
              Valor: produto.Box,
            };
          }
        });

        const promises = [];

        for (const chave in this.contexto[this.contexto.length - 1]
          .resultados) {
          var cep = this.contexto[this.contexto.length - 1].campos.CEP;

          var objetoConsulta = {};
          objetoConsulta["cpf_cnpj"] =
            this.contexto[this.contexto.length - 1].campos.CPF;
          objetoConsulta["data_ref"] =
            this.contexto[this.contexto.length - 1].campos.DATA_REF;
          objetoConsulta["cep"] = cep ? cep : "";
          objetoConsulta["cod_ref"] = chave;
          objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
          objetoConsulta["modo_resultado"] = "codigo";
          objetoConsulta["cod_agrupador"] =
            this.contexto[this.contexto.length - 1].cod_agr;

          const promise = consultasService
            .realizaConsultaBox(objetoConsulta)
            .then((resultado) => {
              this.resultado = resultado;
              this.contexto[this.contexto.length - 1].resultados[chave] = {
                ...this.resultado,
              };
              return { chave, resultado };
            });
          promises.push(promise);
        }

        promises.forEach((promise) => {
          promise.then(({ chave, resultado }) => {
            if (this.contexto[0].resultados[chave]) {
              this.atribuiResultados(chave);
            }
          });
        });

        this.salvaHistoricoTrilha();

        this.$emit("liberarPdf");
        this.loading = false;
      }
    },

    salvaHistoricoTrilha() {
      if (this.consultasTrilha) {
        const novaConsulta = this.consultasTrilha.find(
          (obj) => obj.cod_agrupador == "loading"
        );

        if (novaConsulta) {
          novaConsulta.cod_agrupador = this.codigo_agrupador;
        }
        var novoHistorico = {};
        novoHistorico = {
          contexto: this.contexto,
          listaBlocos: this.listaBlocos,
          consultaErros: this.consultaErros,
          produtosSelecionados: this.produtosSelecionados,
        };

        if (!novaConsulta.historico) {
          novaConsulta.historico = {};
        }

        novaConsulta.historico = { ...novoHistorico };
        this.trilhaSelecionada = this.consultasTrilha.find(
          (consulta) => consulta.cod_agrupador == this.codigo_agrupador
        );
      }
    },

    async atribuiResultados(chave) {
      this.resetAlertasPf();
      var descricao =
        this.contexto[0].resultados[chave].atributos != null
          ? this.contexto[0].resultados[chave].atributos.descricao
          : "";

      if (chave.startsWith("BOXPRPJ00")) {
        var consulta = {
          Consulta: this.contexto[0].resultados[chave],
        };
        this.resultadosFaturamentoPresumido.push(consulta);
      }
      if (descricao && descricao != "") {
        if (descricao.includes("Dados Cadastrais")) {
          this.resultadoDadosCadastrais =
            this.contexto[0].resultados[chave].atributos;
          this.loading = false;

          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoDadosCadastrais`].abreEsteBloco();
              this.$refs[`blocoAlertasGerais`].abreEsteBloco();
              this.$set(this.blocoStatus, 8, "ok");
            }, 1000);
          });

          this.nomeCompleto = this.resultadoDadosCadastrais.bk_101_001_nome;

          this.data =
            this.contexto[0].resultados.BOXSUPPF001.sumario_consulta.data_transacao_brt
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("/");

          this.hora =
            this.contexto[0].resultados.BOXSUPPF001.sumario_consulta.data_transacao_brt.slice(
              11,
              19
            );

          this.valorDataRef = "";
          if (this.contexto[0].campos.DATA_REF) {
            const data = this.contexto[0].campos.DATA_REF;
            if (data) {
              this.valorDataRef = data;
              this.dataDaConsulta = data;
            }
          }
        }

        if (descricao.includes("Sonar Outbox")) {
          this.resultadoSonar = this.contexto[0].resultados[chave].atributos;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoSonarOutbox`].abreEsteBloco();
            }, 1000);
          });
        }

        if (descricao.includes("Renda presumida")) {
          this.resultadoRendaPresumida =
            this.contexto[0].resultados[chave].atributos;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoRendaPresumida`].abreEsteBloco();
            }, 1000);
          });
        }

        if (descricao.includes("Associações Pessoais")) {
          this.resultadoAssociacoesPessoais =
            this.contexto[0].resultados[chave].atributos;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoAssociaçõesPessoais`].abreEsteBloco();
            }, 1000);
          });
        }

        if (descricao.includes("Endereços e geolocalidades")) {
          this.resultadoEnderecosGeolocalidades =
            this.contexto[0].resultados[chave].atributos;
          this.resultadoEnderecosGeolocalidadesParametros =
            this.contexto[0].resultados[chave].sumario_consulta.parametros;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoEndereçoseGeolocalidades`].abreEsteBloco();
            }, 1000);
          });
        }

        if (descricao.includes("Mapa Geral de Riscos")) {
          this.resultadoMapaRiscos =
            this.contexto[0].resultados[chave].atributos;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoMapaGeraldeRiscos`].abreEsteBloco();
            }, 1000);
          });
        }

        if (descricao.includes("Conexões com empresas")) {
          this.resultadoConexoesEmpresas =
            this.contexto[0].resultados[chave].atributos;

          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoConexõescomEmpresas`].abreEsteBloco();
            }, 1000);
          });
        }
        if (descricao.includes("Histórico de Passagens")) {
          this.resultadoHistoricoDePassagens =
            this.contexto[0].resultados[chave].atributos;

          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs[`blocoHistóricodePassagens`].abreEsteBloco();
            }, 1000);
          });
        }

        for (let i = 0; i < this.listaBlocos.length; i++) {
          if (this.compararValores(this.listaBlocos[i].descricao, descricao)) {
            const index = this.listaBlocos[i].id - 1;
            this.$set(this.blocoStatus, index, "ok");
          }
        }

        if (this.contexto[0].resultados[chave].erro != null) {
          this.$set(
            this.consultaErros,
            chave,
            this.contexto[0].resultados[chave].erro.erro
          );

          const bloco = this.listaBlocos.find((obj) => obj.box === chave);

          if (bloco) {
            var index = bloco.id - 1;
            this.$set(this.blocoStatus, index, "erro");
          }
        }
      }
      this.$emit("liberarPdf");
    },

    initRefsList() {
      this.refsList = Object.keys(this.$refs).map(
        (refName) => this.$refs[refName]
      );
    },

    initObserver() {
      const options = {
        root: null,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0,
      };

      this.observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );

      this.refsList.forEach((ref) => {
        if (ref && ref.$el) {
          this.observer.observe(ref.$el);
        }
      });
    },

    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const refName = Object.keys(this.$refs).find((refName) => {
            return this.$refs[refName].$el === entry.target;
          });

          if (refName) {
            this.refAtual = refName;
          }
        }
      });
    },

    scrollToBlock(index, bloco) {
      if (this.blocoStatus[index] != "loading") {
        const descricaoBloco = bloco.descricao.replace(/\s/g, "");
        const blockElement = this.$refs[`bloco${descricaoBloco}`].$el;
        if (blockElement) {
          blockElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    },

    scrollToBlockAlerta(bloco) {
      const blockElement = this.$refs[bloco].$el;
      if (blockElement) {
        blockElement.scrollIntoView({ behavior: "smooth" });
      }
    },

    abrirContato() {
      const novaUrl = this.$router.resolve({
        path: "/suporte/contato",
        query: { source: "consulta" },
      }).href;
      window.open(novaUrl, "_blank");
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.toLowerCase();
      };

      const str1Preparada = prepararString(removerAcentos(str1));
      const str2Preparada = prepararString(removerAcentos(str2));

      return str2Preparada.includes(str1Preparada);
    },

    recebePrefTooltips(pref) {
      this.exibirTootipsConsultas = pref;
    },

    formatarCPF(cpf) {
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");

        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3}|\*{3})\.(\d{3}|\*{3})\.(\d{3}|\*{3})-(\d{2}|\*{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
    ]),

    ...mapActions(storeAlertasSuperBox, ["resetAlertasPf"]),
    ...mapActions(blocosSuperBoxStore, ["alteraBloco", "abreTodosBlocos"]),

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      exibirTootipsConsultas: "exibirTootipsConsultas",
    }),

    ...mapWritableState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      consultasTrilha: "consultasTrilha",
      trilhaSelecionada: "trilhaSelecionada",
      dataDaConsulta: "dataDaConsulta",
    }),

    tooltipsBloco0() {
      return listaTooltips.inicio;
    },

    listaBlocos() {
      return produtosSuperBox.superBox.Blocos.filter(
        (item) => !item.box.includes("_")
      );
    },

    valorCPF() {
      if (this.contexto.length > 0) {
        const cpf = this.contexto[0].campos.CPF || this.contexto[0].campos.CNPJ;
        const cpfNumeros = cpf.replace(/[.\-]/g, "");
        return cpfNumeros;
        //   var cpf = mascaras.cpf(this.contexto[0].campos.CPF);
        //   if (this.ocultarDados) {
        //     return cpf.replace(
        //       /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
        //       "***.$2.$3-**"
        //     );
        //   } else {
        //     return cpf;
        //   }
      } else {
        return "";
      }
    },

    nomeCompletoFiltro() {
      var nome = this.nomeCompleto;
      if (this.ocultarDados) {
        let palavras = nome.split(" ");

        if (palavras.length > 1) {
          for (let i = 1; i < palavras.length; i++) {
            palavras[i] = palavras[i].replace(/\S/g, "*");
          }
        }
        return palavras.join(" ");
      } else {
        return nome;
      }
    },
  },

  async created() {
    this.loading = true;
    this.$emit("travarPdf");

    this.ocultarDados = await this.syncPreferenciaOcultarDados();
    this.exibirTootipsConsultas = await this.syncPreferenciaConsultasTooltips();

    if (!this.liberaConsultaSuperbox) {
      Object.keys(this.contexto[this.contexto.length - 1].resultados).forEach(
        (chave) => {
          this.atribuiResultados(chave);
        }
      );
    }

    this.$nextTick(() => {
      this.geraConsultas();
    });
  },

  mounted() {
    const contexto = {
      codigo_agrupador: this.codigo_agrupador,
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    this.$nextTick(() => {
      setTimeout(() => {
        this.initRefsList();
        this.initObserver();
      }, 2000);
    });
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
.content-sb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentFull {
  width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 960px) {
    align-items: flex-start;
  }
}

.header {
  display: flex;
  width: 910px;
  height: 80px;
  background-color: transparent;
  background-image: url(/img/superbox/Topo_SUPERBOX.png);
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: 65px;
}

.header-title {
  margin: 0 0 5px 0 !important;
  font-weight: 500;
  font-size: 36px;
  color: #4356a5;
}

.body {
  height: 1100px;
  width: 950px;
  margin: 2% auto;
  background-color: transparent;
  background-image: url(/img/superbox/fundo.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
}

.cracha {
  background-color: transparent;
  background-image: url(/img/superbox/Cracha.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 437px;
  height: 295px;
  padding: 27px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.img-logo {
  height: 80%;
  // position: relative;
  // top: 9px;
  // left: 0px;
}
.img-logo-x {
  height: 120px;
  position: absolute;
  top: 150px;
  right: 76px;
  opacity: 0.2;
}
.col {
  width: 50%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.info-cracha {
  // width: 60%;
  margin: 5px 5px 5px 15px;
  display: flex;
  flex-direction: column;
  cursor: default;
  z-index: 2;

  &.data_ref {
    margin: 0px 5px 5px 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    flex-direction: row;
    gap: 5px;
  }
}
.info-title {
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  &.data_ref {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.info-res {
  color: white;
  font-weight: 300;
}
.info-blocos {
  display: grid;
  justify-content: center;
  grid-template-columns: 0.1fr 0.1fr 0.1fr;
  margin: 35px 0 5px 0;
}

.loading {
  position: fixed;
  top: 100px;
}

.footer-sup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 960px;
  margin: 5px 0;
  cursor: default;
}

.footer-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  z-index: 10;
  gap: 5px;

  .link {
    color: #0070c0;
    cursor: pointer;

    &:hover {
      font-weight: 500;
    }
  }
}

.question {
  border: 2px solid #0070c0c9;
  color: #0070c0c9;
  border-radius: 50%;
  padding: 2px;
  transition: 0.3s;
  font-size: 16px;
}
</style>
<!-- <style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" /> -->
