<template>
  <div class="box-valor genero">
    <div class="circle-container">
      <div
        class="circle"
        :class="{
          fem: genero == 'F',
          masc: genero == 'M',
          na: genero == 'NA',
          nulo: genero != 'F' && genero != 'M' && genero != 'NA',
        }"
      >
        <img :src="iconFemCinza" class="img-circle fem" v-if="genero != 'F'" />
        <img :src="iconFemBranco" class="img-circle fem" v-if="genero == 'F'" />
        <img
          :src="iconMascCinza"
          class="img-circle masc"
          v-if="genero != 'M'"
        />
        <img
          :src="iconMascBranco"
          class="img-circle masc"
          v-if="genero == 'M'"
        />
        <img :src="iconNACinza" class="img-circle na" v-if="genero != 'NA'" />
        <img :src="iconNABranco" class="img-circle na" v-if="genero == 'NA'" />
        <div
          class="horizontal-line"
          :class="{
            nulo: genero != 'F' && genero != 'M' && genero != 'NA',
          }"
        ></div>
        <div
          class="vertical-line"
          :class="{
            nulo: genero != 'F' && genero != 'M' && genero != 'NA',
          }"
        ></div>
      </div>
      <span class="genero-valor fem" v-if="tipoExibidoDelay != 'documento'">
        {{ proporcaoF }}
      </span>
      <span class="genero-valor masc" v-if="tipoExibidoDelay != 'documento'">
        {{ proporcaoM }}
      </span>
      <span class="genero-valor na" v-if="tipoExibidoDelay != 'documento'">
        {{ proporcaoNA }}
      </span>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";

export default {
  name: "GeneroInferido",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,

      iconFemCinza: "/img/superbox/bloco-sonar/Feminino-cinza.png",
      iconFemBranco: "/img/superbox/bloco-sonar/Feminino-branco.png",
      iconMascCinza: "/img/superbox/bloco-sonar/Masculino-cinza.png",
      iconMascBranco: "/img/superbox/bloco-sonar/Masculino-Branco.png",
      iconNACinza: "/img/superbox/bloco-sonar/NA-cinza.png",
      iconNABranco: "/img/superbox/bloco-sonar/NA-branco.png",
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.tipoExibidoDelay = newVal;
        }, 500);
      }
    },
  },

  methods: {},

  created() {},

  computed: {
    genero() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[
          `boxauxsuppf002_class_documento_genero_inferido`
        ];
      } else {
        return this.resultados[
          `bk_111_001_class_${this.tipoExibidoDelay}_genero_inferido`
        ];
      }
    },

    proporcaoF() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_genero_inferido_proporcao_feminino`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },

    proporcaoM() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_genero_inferido_proporcao_masculino`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },
    proporcaoNA() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_genero_inferido_proporcao_sem_classificacao`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;

  &.genero {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}

.genero-valor {
  position: absolute;
  text-align: center;
  width: 20px;
  font-size: 11px;

  &.fem {
    right: 9px;
    top: 45px;
  }

  &.masc {
    right: 90px;
    top: 45px;
  }

  &.na {
    right: 9px;
    top: 105px;
  }
}

.valor-linha {
  text-align: start;
  display: flex;
  justify-content: space-between;
  &.assoc {
    margin-top: 4px;
  }

  .valor {
    display: flex;
    gap: 10px;
  }
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: relative;
  width: 70px;
  height: 70px;
  border: 2px solid #a69bff;
  border-radius: 50%;

  &.nulo {
    border: 2px solid #b8b8b8;
  }

  &.fem {
    background-image: linear-gradient(to bottom, transparent 50%, white 50%),
      linear-gradient(
        to right,
        transparent 50%,
        rgba(160, 148, 255, 1) 51%,
        rgba(134, 123, 221, 1) 75%,
        rgba(91, 84, 153, 1) 100%
      );
    background-size: 100% 100%;
  }
  &.masc {
    background-image: linear-gradient(to bottom, transparent 50%, white 50%),
      linear-gradient(
        to left,
        transparent 50%,
        rgba(91, 84, 153, 1) 51%,
        rgba(134, 123, 221, 1) 75%,
        rgba(160, 148, 255, 1) 100%
      );
    background-size: 100% 100%;
  }
  &.na {
    background-image: linear-gradient(
      to top,
      #5b5499 0%,
      #867bdd 25%,
      #a094ff 50%,
      transparent 50%
    );

    background-size: 100% 100%;
  }
}

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #a69bff;
  transform: translateY(-50%);

  &.nulo {
    background-color: #b8b8b8;
  }
}

.vertical-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 50%;
  background-color: #a69bff;
  transform: translateX(-50%);

  &.nulo {
    background-color: #b8b8b8;
  }
}

.img-circle {
  width: 25px;
  position: absolute;

  &.fem {
    top: 6px;
    left: 36px;
    opacity: 0.8;
  }

  &.masc {
    top: 6px;
    left: 7px;
  }
  &.na {
    top: 37px;
    left: 22px;
  }
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
