var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-valor horiz"},[_c('span',{staticClass:"valor-titulo"},[_vm._v("Moradia")]),_c('span',{staticClass:"valor-icones"},_vm._l((5),function(valor,index){return _c('span',{key:index,staticClass:"valores",class:{
        margin: index != 4,
      }},[_c('span',{staticClass:"icon",class:{
          'material-icons': index < _vm.rating,
          'material-symbols-outlined': index >= _vm.rating,
          nulo: _vm.valorMoradia == '-',
        }},[_vm._v(" star ")]),(_vm.tipoExibidoDelay != 'documento')?_c('span',{staticClass:"valor"},[_vm._v(" "+_vm._s(_vm.getPorcentagem(index))+" ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }