<template>
  <div class="box-valor geracao">
    <span
      class="valor-linha geracao"
      :class="{ selecionado: geracao == babyB }"
    >
      <span class="texto" :id="'BlocoSonar-geracao-0' + '-' + lado">
        {{ babyB }}
        <span class="material-symbols-outlined icon">do_not_disturb_on </span>
      </span>
      <span class="valor" v-if="tipoExibidoDelay != 'documento'">{{
        proporcaoBB
      }}</span>
    </span>
    <span class="valor-linha geracao" :class="{ selecionado: geracao == gerX }">
      <span class="texto" :id="'BlocoSonar-geracao-1' + '-' + lado">
        {{ gerX }}
      </span>
      <span class="valor" v-if="tipoExibidoDelay != 'documento'">{{
        proporcaoGerX
      }}</span>
    </span>
    <span
      class="valor-linha geracao"
      :class="{ selecionado: geracao == millen }"
    >
      <span class="texto" :id="'BlocoSonar-geracao-2' + '-' + lado">
        {{ millen }}
      </span>
      <span class="valor" v-if="tipoExibidoDelay != 'documento'">{{
        proporcaoMill
      }}</span>
    </span>
    <span class="valor-linha geracao" :class="{ selecionado: geracao == gerZ }">
      <span class="texto" :id="'BlocoSonar-geracao-3' + '-' + lado">
        {{ gerZ }}
        <span class="material-symbols-outlined icon"> add_circle </span>
      </span>
      <span class="valor" v-if="tipoExibidoDelay != 'documento'">{{
        proporcaoGerZ
      }}</span>
    </span>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";

export default {
  name: "Geracao",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
    lado: String,
  },

  data() {
    return {
      babyB: "Baby Boomers",
      gerX: "Geração X",
      millen: "Millennials",
      gerZ: "Geração Z",

      tipoExibidoDelay: this.tipoExibido,
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.tipoExibidoDelay = newVal;
        }, 500);
      }
    },
  },

  methods: {},

  created() {},

  computed: {
    geracao() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[`boxauxsuppf002_class_documento_geracao`];
      } else {
        return this.resultados[
          `bk_111_001_class_${this.tipoExibidoDelay}_geracao`
        ];
      }
    },

    proporcaoBB() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_geracao_proporcao_baby_boomers`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },

    proporcaoGerX() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_geracao_proporcao_geracao_x`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },

    proporcaoMill() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_geracao_proporcao_millennials`
          ];
        return valor !== "-" ? valor + "%" : valor;
      }
    },
    proporcaoGerZ() {
      if (this.tipoExibidoDelay == "documento") {
        return null;
      } else {
        const valor =
          this.resultados[
            `bk_111_001_num_${this.tipoExibidoDelay}_geracao_proporcao_geracao_z`
          ];

        return valor !== "-" ? valor + "%" : valor;
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 7px 0 7px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
}

.valor-linha {
  text-align: start;
  display: flex;
  justify-content: space-between;
  line-height: 11px;
  height: 22px;
  display: flex;
  align-items: center;

  .texto {
    height: 12px;
  }

  .valor {
    display: flex;
    gap: 10px;
    font-size: 11px;
  }

  &.selecionado {
    background: linear-gradient(225deg, #5b5499 0%, #867bdd 50%, #a094ff 100%);
    padding: 0px 6px;
    border-radius: 15px;
    color: white;
    width: 108%;
    position: relative;
    right: 7px;
    height: 25px;
    margin: 2px 0 2px 2px;
  }
}

.icon {
  font-size: 13px;
  height: 12px;
  position: absolute;
  line-height: 14px;
  width: 16px;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
