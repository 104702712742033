<template>
  <div class="box-valor">
    <div class="renda-box">
      <svg
        width="100"
        height="72"
        viewBox="0 0 100 70"
        xmlns="http://www.w3.org/2000/svg"
        style="cursor: pointer"
      >
        <defs>
          <linearGradient id="myGradient" x1="100%" y1="0%" x2="0%" y2="0%">
            <stop offset="0%" stop-color="rgba(91, 84, 153, 1)" />
            <stop offset="50%" stop-color="rgba(134, 123, 221, 1)" />
            <stop offset="100%" stop-color="rgba(160, 148, 255, 1)" />
          </linearGradient>
        </defs>
        <polygon
          v-for="(nivel, index) in niveis"
          :key="'legenda-' + nivel.nome"
          :id="'legenda-' + nivel.nome + '-' + lado"
          :points="nivel.points"
          class="polygon"
          :class="{ selecionado: isSelecionado(index) }"
          :style="{ stroke: strokeColor }"
        />
      </svg>
      <span v-if="exibirTootipsConsultas">
        <b-tooltip
          v-for="(nivel, index) in niveis"
          :key="'legenda-' + nivel.nome + '-' + lado"
          :target="'legenda-' + nivel.nome + '-' + lado"
          triggers="hover"
          placement="left"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          {{ nomesRenda[index] }}
        </b-tooltip>
      </span>
      <span class="renda-texto">{{ textoRenda }}</span>
      <template v-if="tipoExibidoDelay != 'documento'">
        <span
          v-for="nivel in niveisRenda"
          :class="'renda-valor ' + nivel"
          :key="nivel"
        >
          {{ getRendaValor(nivel) }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "RendaPresumida",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
    lado: String,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,
      niveisRenda: ["altissima", "alta", "media", "baixa", "baixissima"],
      nomesRenda: ["Baixíssima", "Baixa", "Média", "Alta", "Altíssima"],

      niveis: [
        { nome: "baixissima", points: "20,60 80,60 85,70 15,70" },
        { nome: "baixa", points: "27.5,45 72.5,45 77.5,55 22.5,55" },
        { nome: "media", points: "35,30 65,30 70,40 30,40" },
        { nome: "alta", points: "42.5,15 57.5,15 62.5,25 37.5,25" },
        { nome: "altissima", points: "50,0 50,0 55,10 45,10" },
      ],
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.tipoExibidoDelay = newVal;
        }, 500);
      }
    },
  },

  methods: {
    isSelecionado(index) {
      const posicao = this.nomesRenda.indexOf(this.textoRenda);
      return index <= posicao;
    },

    getRendaValor(nivel) {
      var nivelTratado = nivel
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      const chave = `bk_111_001_num_${this.tipoExibidoDelay}_renda_presumida_proporcao_${nivelTratado}`;
      return this.resultados[chave] != "-"
        ? this.resultados[chave] + "%"
        : this.resultados[chave];
    },
  },

  created() {},

  computed: {
    // grafRenda() {
    //   if (this.tipoExibidoDelay == "documento") {
    //     var valor =
    //       this.resultados[`boxauxsuppf002_class_documento_renda_presumida`];
    //   } else {
    //     var valor =
    //       this.resultados[
    //         `bk_111_001_class_${this.tipoExibidoDelay}_renda_presumida`
    //       ];
    //   }

    //   // const valor = "baixissima";
    //   if (valor == "baixissima") {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide1.png";
    //   } else if (valor == "baixa") {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide2.png";
    //   } else if (valor == "media") {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide3.png";
    //   } else if (valor == "alta") {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide4.png";
    //   } else if (valor == "altissima") {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide5.png";
    //   } else {
    //     return "/img/superbox/bloco-sonar/RendaPresumida-piramide0-cinza.png";
    //   }
    // },

    textoRenda() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[
          `boxauxsuppf002_class_documento_renda_presumida`
        ];
      } else {
        return this.resultados[
          `bk_111_001_class_${this.tipoExibidoDelay}_renda_presumida`
        ];
      }
    },

    strokeColor() {
      const posicao = this.nomesRenda.indexOf(this.textoRenda);
      return posicao >= 0 ? "#a69bff" : "#b8b8b8";
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
}

.renda-box {
  display: flex;
  flex-direction: column;

  .renda-img {
    height: 64px;
    width: 75px;
  }

  .renda-valor {
    position: absolute;
    font-size: 11px;

    &.altissima {
      right: 42px;
      top: 38px;
    }
    &.alta {
      right: 34px;
      top: 52px;
    }
    &.media {
      right: 26px;
      top: 66px;
    }
    &.baixa {
      right: 18px;
      top: 80px;
    }
    &.baixissima {
      right: 10px;
      top: 94px;
    }
  }
}

.renda-texto {
  max-width: 100px;
  overflow: hidden;
}

.legenda {
  position: absolute;
  height: 10px;
  background-color: transparent;

  &.Altíssima {
    right: 75px;
    top: 42px;
    width: 10px;
  }
  &.Alta {
    right: 71px;
    top: 56px;
    width: 20px;
  }
  &.Média {
    right: 60px;
    top: 69px;
    width: 40px;
  }
  &.Baixa {
    right: 50px;
    top: 83px;
    width: 60px;
  }
  &.Baixíssima {
    width: 75px;
    top: 97px;
    right: 43px;
  }
}

.polygon {
  fill: transparent;
  stroke-width: 2;
  transition: fill 0.3s ease;
}
.polygon:hover {
  stroke: #b8b8b8;
  fill: #b8b8b8;
}

.polygon.selecionado {
  stroke: #a69bff !important;
  fill: url(#myGradient);
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
