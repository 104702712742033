<template>
  <div class="box-valor">
    <span
      class="valor-col"
      v-for="(item, i) in itens"
      :key="item.tipo"
      :class="{ 'sb-right2': i != 3 }"
    >
      <span
        class="box-local"
        :class="{ selecionado: local == item.titulo, nulo: local == '-' }"
      >
        <img
          class="img-local"
          :src="local == item.titulo ? item.imagemAtiva : item.imagem"
        />
        <span class="local-texto">
          {{ item.titulo }}
        </span>
      </span>
      <span class="valor-texto" v-if="tipoExibidoDelay != 'documento'">
        {{ getPorcentagem(item.tipo) }}
      </span>
    </span>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapState, mapActions } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "ClassificacaoLocal",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,

      itens: [
        {
          tipo: "interior",
          titulo: "Interior",
          imagem: "/img/superbox/bloco-sonar/Interior-preto.png",
          imagemAtiva: "/img/superbox/bloco-sonar/Interior-branco.png",
        },
        {
          tipo: "rural",
          titulo: "Rural",
          imagem: "/img/superbox/bloco-sonar/Rural-preto.png",
          imagemAtiva: "/img/superbox/bloco-sonar/Rural-branco.png",
        },
        {
          tipo: "metropole",
          titulo: "Urbano",
          imagem: "/img/superbox/bloco-sonar/Metropole-preto.png",
          imagemAtiva: "/img/superbox/bloco-sonar/Metropole-branco.png",
        },
        {
          tipo: "litoral",
          titulo: "Litoral",
          imagem: "/img/superbox/bloco-sonar/Litoral-preto.png",
          imagemAtiva: "/img/superbox/bloco-sonar/Litoral-branco.png",
        },
      ],
    };
  },
  watch: {
    tipoExibido(newVal, oldVal) {
      setTimeout(() => {
        this.tipoExibidoDelay = newVal;
      }, 500);
    },
  },

  methods: {
    getPorcentagem(tipo) {
      if (this.tipoExibidoDelay != "documento") {
        const atributo = `bk_111_001_num_${
          this.tipoExibidoDelay
        }_local_moradia_proporcao_${tipo.toLowerCase()}`;
        return this.resultados[atributo] != "-"
          ? this.resultados[atributo] + "%"
          : this.resultados[atributo];
      } else {
        return null;
      }
    },
  },

  created() {},

  computed: {
    local() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[`boxauxsuppf002_class_documento_local_moradia`];
      } else {
        return this.resultados[
          `bk_111_001_class_${this.tipoExibidoDelay}_local_moradia`
        ];
      }
      // return "interior";
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 10px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
}

.valor-texto {
  line-height: 14px;
  font-size: 11px;
}

.valor-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;

  .box-local {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 2px solid #a69bff;
    border-radius: 10px;
    width: 65px;
    height: 65px;
    font-size: 11px;

    &.selecionado {
      background: linear-gradient(
        225deg,
        rgba(91, 84, 153, 1) 0%,
        rgba(134, 123, 221, 1) 50%,
        rgba(160, 148, 255, 1) 100%
      );
      color: white;
    }

    &.nulo {
      border: 2px solid #b8b8b8;
    }
  }
  .img-local {
    width: 40px;
  }
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
