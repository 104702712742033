var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-valor genero"},[_c('div',{staticClass:"circle-container"},[_c('div',{staticClass:"circle",class:{
        fem: _vm.genero == 'F',
        masc: _vm.genero == 'M',
        na: _vm.genero == 'NA',
        nulo: _vm.genero != 'F' && _vm.genero != 'M' && _vm.genero != 'NA',
      }},[(_vm.genero != 'F')?_c('img',{staticClass:"img-circle fem",attrs:{"src":_vm.iconFemCinza}}):_vm._e(),(_vm.genero == 'F')?_c('img',{staticClass:"img-circle fem",attrs:{"src":_vm.iconFemBranco}}):_vm._e(),(_vm.genero != 'M')?_c('img',{staticClass:"img-circle masc",attrs:{"src":_vm.iconMascCinza}}):_vm._e(),(_vm.genero == 'M')?_c('img',{staticClass:"img-circle masc",attrs:{"src":_vm.iconMascBranco}}):_vm._e(),(_vm.genero != 'NA')?_c('img',{staticClass:"img-circle na",attrs:{"src":_vm.iconNACinza}}):_vm._e(),(_vm.genero == 'NA')?_c('img',{staticClass:"img-circle na",attrs:{"src":_vm.iconNABranco}}):_vm._e(),_c('div',{staticClass:"horizontal-line",class:{
          nulo: _vm.genero != 'F' && _vm.genero != 'M' && _vm.genero != 'NA',
        }}),_c('div',{staticClass:"vertical-line",class:{
          nulo: _vm.genero != 'F' && _vm.genero != 'M' && _vm.genero != 'NA',
        }})]),(_vm.tipoExibidoDelay != 'documento')?_c('span',{staticClass:"genero-valor fem"},[_vm._v(" "+_vm._s(_vm.proporcaoF)+" ")]):_vm._e(),(_vm.tipoExibidoDelay != 'documento')?_c('span',{staticClass:"genero-valor masc"},[_vm._v(" "+_vm._s(_vm.proporcaoM)+" ")]):_vm._e(),(_vm.tipoExibidoDelay != 'documento')?_c('span',{staticClass:"genero-valor na"},[_vm._v(" "+_vm._s(_vm.proporcaoNA)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }