<template>
  <div class="box-valor distancia">
    <div class="valor-linha">
      <span class="texto" :id="'BlocoSonar-distancia-eds' + '-' + lado"
        >EDS</span
      >
      <span class="barra" :class="{ inativo: distanciaEDS == '-' }">
        <span class="aviso" v-if="distanciaEDS == '0 m'">
          CEP contido no EDS
        </span>
        <span
          class="barra-valor"
          :style="{ width: calcularPorcentagem(distanciaEDS) + '%' }"
        ></span>
      </span>
      <span
        class="material-symbols-outlined valor-icone"
        v-if="distanciaEDS == '0m'"
      >
        report
      </span>
      <span class="valor">{{ formatarNumeroComPonto(distanciaEDS) }}</span>
    </div>
    <div class="valor-linha">
      <span class="texto" :id="'BlocoSonar-distancia-efs' + '-' + lado"
        >EFS</span
      >
      <span class="barra" :class="{ inativo: distanciaEFS == '-' }">
        <span
          class="barra-valor"
          :style="{ width: calcularPorcentagem(distanciaEFS) + '%' }"
        ></span>
      </span>

      <span class="valor">{{ formatarNumeroComPonto(distanciaEFS) }}</span>
    </div>
    <div class="valor-linha">
      <span class="texto" :id="'BlocoSonar-distancia-eap' + '-' + lado"
        >EAP</span
      >
      <span class="barra" :class="{ inativo: distanciaEAP == '-' }">
        <span
          class="barra-valor"
          :style="{ width: calcularPorcentagem(distanciaEAP) + '%' }"
        ></span>
      </span>
      <span class="valor">{{ formatarNumeroComPonto(distanciaEAP) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DistanciaPontosInteresse",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
    lado: String,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      setTimeout(() => {
        this.tipoExibidoDelay = newVal;
      }, 500);
    },
  },

  methods: {
    calcularPorcentagem(valor) {
      if (valor == "+ 5000 m") {
        return 100;
      } else if (valor === null || valor == "-") {
        return 0;
      } else {
        let valorNumerico = parseInt(valor.replace(" m", ""), 10);
        if (valorNumerico > 5000) {
          return 100;
        } else {
          var porcentagem = (valorNumerico / 5000) * 100;
          if (porcentagem > 0 && porcentagem < 5) {
            porcentagem = 5;
          }
          return `${porcentagem.toFixed(2)}`;
        }
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },
  },

  created() {},

  computed: {
    distanciaEDS() {
      // return 0 + "m";
      var atributo =
        this.tipoExibidoDelay == "documento"
          ? `boxauxsuppf002_num_${this.tipoExibidoDelay}_distancia_minima_eds`
          : `bk_111_001_num_${this.tipoExibidoDelay}_distancia_minima_eds_media`;

      let valor = this.resultados[atributo];

      if (valor === "-") {
        return valor;
      } else {
        valor = valor * 1000;
        if (valor > 5000) {
          return "+ 5000 m";
        } else {
          return valor + " m";
        }
      }
    },

    distanciaEFS() {
      // return 3000 + "m";
      var atributo =
        this.tipoExibidoDelay == "documento"
          ? `boxauxsuppf002_num_${this.tipoExibidoDelay}_distancia_minima_efs`
          : `bk_111_001_num_${this.tipoExibidoDelay}_distancia_minima_efs_media`;

      let valor = this.resultados[atributo];

      if (valor === "-") {
        return valor;
      } else {
        valor = valor * 1000;
        if (valor > 5000) {
          return "+ 5000 m";
        } else {
          return valor + " m";
        }
      }
    },

    distanciaEAP() {
      var atributo =
        this.tipoExibidoDelay == "documento"
          ? `boxauxsuppf002_num_${this.tipoExibidoDelay}_distancia_minima_eap`
          : `bk_111_001_num_${this.tipoExibidoDelay}_distancia_minima_eap_media`;

      let valor = this.resultados[atributo];

      if (valor === "-") {
        return valor;
      } else {
        valor = valor * 1000;
        if (valor > 5000) {
          return "+ 5000 m";
        } else {
          return valor + " m";
        }
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  z-index: -1;

  &.distancia {
    padding: 2px 10px 10px 10px;
    justify-content: space-between;
  }
}

.valor-linha {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.texto {
  width: 30px;
}

.barra {
  border: 2px solid #a69bff;
  height: 20px;
  background-color: transparent;
  width: 125px;
  display: flex;
  padding: 2px;
  align-items: center;

  &.inativo {
    border: 2px solid #b8b8b8;
  }
}

.aviso {
  font-size: 11px;
  line-height: 12px;
  font-weight: 300;
  text-align: center;
  width: 100%;
}

.valor-icone {
  position: absolute;
  right: 37px;
  font-weight: 300;
  font-size: 24px;
  // color: #a69bff;
  color: #d65532;
}

.barra-valor {
  background: linear-gradient(
    225deg,
    rgba(91, 84, 153, 1) 0%,
    rgba(134, 123, 221, 1) 50%,
    rgba(160, 148, 255, 1) 100%
  );
  border-radius: 10px;
  height: 12px;
}

.valor-texto {
  line-height: 14px;
}
.valor {
  line-height: 14px;
  width: 55px;
  text-align: right;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
